import { Phone, Mail } from "lucide-react";
import { FaWhatsapp } from "react-icons/fa";
import React, { useState, useEffect } from "react";

const waveAnimation = `
  @keyframes wave {
    0% { transform: rotate(0deg); }
    10% { transform: rotate(14deg); }
    20% { transform: rotate(-8deg); }
    30% { transform: rotate(14deg); }
    40% { transform: rotate(-4deg); }
    50% { transform: rotate(10deg); }
    60% { transform: rotate(0deg); }
    100% { transform: rotate(0deg); }
  }
`;

function ContactOptions() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = waveAnimation;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  return (
    <div className="sticky bottom-4 right-4 z-50 float-right mr-4 mb-4">
      {" "}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-white text-blue-600 p-4 rounded-full shadow-lg hover:bg-gray-300 transition-all duration-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center"
        aria-label="Contact options"
      >
        <span
          className="mr-1 text-2xl"
          style={{
            animation: "wave 2.5s infinite",
            display: "inline-block",
            transformOrigin: "70% 70%",
          }}
        >
          👋
        </span>{" "}
        <Phone className="w-6 h-6" />
      </button>
      {isOpen && (
        <>
          <a
            href="tel:+919131959638"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 text-gray-700 hover:text-green-600 transition-colors duration-300"
          >
            <>
              <span className="mr-2 animate-wave">👋</span>
              <Phone className="w-6 h-6 text-green-500" />
            </>
            <span>Call us</span>
          </a>
          <a
            href="mailto:info@example.com"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 text-gray-700 hover:text-green-600 transition-colors duration-300"
          >
            <>
              <span className="mr-2 animate-wave">👋</span>
              <Mail className="w-6 h-6 text-green-500" />
            </>
            <span>Email us</span>
          </a>
          <a
            href="https://wa.me/9131959638"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 text-gray-700 hover:text-green-500 transition-colors duration-300"
          >
            <>
              <span className="mr-2 animate-wave">👋</span>
              <FaWhatsapp className="w-6 h-6 text-green-500" />
            </>
            <span>Chat with us</span>
          </a>
        </>
      )}
    </div>
  );
}

export default ContactOptions;
